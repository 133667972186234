import { graphql, useStaticQuery } from "gatsby"

const useHeader = () => {


  const data = useStaticQuery(graphql`
    query {
      headerNav0: blockContentMenuLinks(id: {eq: "16fa5cd9-631e-5983-bd2f-2ca5ec1faa16"}) {
        info
        relationships {
          items: field_items {
            relationships {
              item: field_navigation_item {
                label: field_label
                uri: field_slug
              }
              children: field_navigation_children {
                label: field_label
                uri: field_slug
              }
            }
          }
        }
      }
      headerNav: blockContentMenuLinks(id: {eq: "83b92a63-19a8-511e-b202-59dc899833b2"}) {
        info
        relationships {
          items: field_items {
            relationships {
              item: field_navigation_item {
                label: field_label
                uri: field_slug
              }
              children: field_navigation_children {
                label: field_label
                uri: field_slug
              }
            }
          }
        }
      }
      menusJson(name: {eq: "main"}) {
        registration{
          label
          url
        }
        login{
          label
          url
        }
      }
    }
  `)

  //console.log(data)

  const res0 = data.headerNav0.relationships.items.map(ele => ({
    label: ele.relationships.item.label,
    url: ele.relationships.item.uri,
    items: ele.relationships.children.map(e => ({
      label: e.label,
      url: e.uri,
    }))
  }))

  const res = data.headerNav.relationships.items.map(ele => ({
    label: ele.relationships.item.label,
    url: ele.relationships.item.uri,
    items: ele.relationships.children.map(e => ({
      label: e.label,
      url: e.uri,
    }))
  }))

  return {
    menu0: res0,
    menu: res,
    registration: data.menusJson.registration,
    login: data.menusJson.login,
  }
}

export default useHeader
