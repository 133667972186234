import React from "react"
import { Link } from "gatsby"
import translations from "../translations/he.json"
import Layout from "../components/layout"
import { useSelector/* , useDispatch */ } from 'react-redux'

const UserPage = ({ location, ...props }) => {

  const user = useSelector(state => state.user)

  const reports = user?.user?.reports ? [...user?.user?.reports].reverse() : []
  //console.log(user.user.reports)
  //const dispatch = useDispatch()

  return (

    <Layout>

      <div className="container mx-auto mt-[90px] lg:mt-[126px] py-12 space-y-8">

        <div className="text-3xl">
          <h1>{translations['user.title']} </h1>
        </div>

        {!user.isLoggedIn &&
          <div className="space-y-3 ">
            <div>שימוש בדף זה דורש התחברות</div>
            <Link to={'/login'} className="block p-3 bg-blue text-white w-fit" >התחברות</Link>
          </div>
        }

        {user.isLoggedIn &&

          <div className="space-y-8">
            <div className="space-y-3">
              {/* <h2 className="text-xl">{translations['user.reports']}</h2> */}
              <div className="">
                {reports.map((r, index) => (
                  <div className="flex" key={index}>
                    <div className="w-96">תאריך הפקת הדוח {r.created}</div>
                    <div>
                      <a href={r.url} target="__blank" className="underline text-blue">סיכום נתוני התוכנית</a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/*
            <div>2. edit profile in future</div>
            <div>3. masqurading</div>
 */}

          </div>
        }

      </div>

    </Layout>

  )
}

export default UserPage
