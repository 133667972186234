import { graphql, useStaticQuery } from "gatsby"

const useFooter = () => {

  const data = useStaticQuery(graphql`
    query {
      footer: blockContentMenuLinks(id: {eq: "0466b69f-e716-55f6-8e78-903e6a97f99b"}) {
        relationships {
          items: field_items {
            relationships {
              item: field_navigation_item {
                label: field_label
                uri: field_slug
              }
              children: field_navigation_children {
                label: field_label
                uri: field_slug
              }
            }
          }
        }
      }
      footer0: blockContentMenuLinks(id: {eq: "df3b26bd-dcc1-5abe-8d95-557f10a7c6ce"}) {
        relationships {
          items: field_items {
            relationships {
              item: field_navigation_item {
                label: field_label
                uri: field_slug
              }
              children: field_navigation_children {
                label: field_label
                uri: field_slug
              }
            }
          }
        }
      }
      menu: menusJson(name: {eq: "footer"}) {
        copyright
      }
      logo: file(sourceInstanceName: {eq: "data"}, base: {eq: "logo-footer.svg"}) {
        base
        publicURL
      }
      logo1: file(sourceInstanceName: {eq: "data"}, base: {eq: "logo-footer-1.svg"}) {
        base
        publicURL
      }
      logo2: file(sourceInstanceName: {eq: "data"}, base: {eq: "logo-footer-2.svg"}) {
        base
        publicURL
      }
      logo3: file(sourceInstanceName: {eq: "data"}, base: {eq: "eec_logo.svg"}) {
        base
        publicURL
      }
      logo4: file(sourceInstanceName: {eq: "data"}, base: {eq: "minhelet_logo.svg"}) {
        base
        publicURL
      }

    }
  `)

  //console.log(data)
  //console.log(data.options)

  const menu = data.footer.relationships.items.map(ele => ({
    label: ele?.relationships?.item?.label,
    url: ele?.relationships?.item?.uri,
    items: ele?.relationships?.children.map(e => ({
      label: e.label,
      url: e.uri,
    }))
  }))

  const menu0 = data.footer0.relationships.items.map(ele => ({
    label: ele?.relationships?.item?.label,
    url: ele?.relationships?.item?.uri,
    items: ele?.relationships?.children.map(e => ({
      label: e.label,
      url: e.uri,
    }))
  }))

  return {
    menu: menu, // data.menu.items,
    menu0: menu0, // data.menu.items,
    logo: data.logo4,
    logos: [data.logo1, data.logo3/* , data.logo3 */],
    logos0: [data.logo, data.logo2 /* , data.logo3 */],
    copyright: data.menu.copyright
  }

}

export default useFooter
