import React, { useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import arrow from "../images/menu-arrow.svg";
import arrow2 from "../images/arrow.svg";

const Navigation = ({ items }) => {
  const location = useLocation();
  const pathname = location.pathname;
  //console.log(pathname)

  const links = items;

  return (
    <nav className="">
      <ul className="list-none	 flex flex-row justify-between w-full text-base font-bold content-center space-x-8 rtl:space-x-reverse h-full">
        {links.map((item, i) => (
          <li key={i}>
            <MenuItem
              item={item}
              index={i}
              active={item.url === decodeURI(pathname)}
            />
          </li>
        ))}
      </ul>
    </nav>
  );
};

const MenuItem = ({ item, index, active }) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleFocus = () => {
    setShowMenu(true);
  };

  const onKeyDown = (e) => {
    switch (e.keyCode) {
      case 27:
        setShowMenu(false);
        break;

      default:
        break;
    }
  };
  if (item?.items.length === 0) {
    return (
      <Link
        className={"hover:text-blue " + (active ? " text-blue" : "")}
        to={item.url}
      >
        {item.label}
      </Link>
    );
  } else {
    return (
      <div
        className="uppercase flex relative"
        onMouseLeave={() => setShowMenu(false)}
        //role="menuitem"
        tabIndex={"menuItem" + index}
        //onBlur={() => setShowMenu(false)}
        onKeyDown={onKeyDown}
        onFocus={handleFocus}
      >
        <button
          className={
            " hover:text-blue uppercase " + (showMenu ? " text-blue" : "")
          }
          id={index + 1}
          onMouseEnter={() => setShowMenu(true)}
          aria-expanded={showMenu}
          role="button"
          onClick={() => setShowMenu(!showMenu)}
        >
          <div className="inline">{item.label}</div>
        </button>

        {showMenu && <SubMenu items={item.items} />}
      </div>
    );
  }
};

const SubMenu = ({ items }) => {
  
  return (
    <ul
      className={
        "pt-4  w-[280px] bg-white absolute -right-4 top-[24px]  shadow text-base"
      }
    >
      {items.map((e, j) => (
        <li
          className="mb-1 group hover:bg-light-blue border-white border-r-4 hover:border-yellow flex flex-row block w-full pr-4 justify-between items-center h-10"
          key={j}
        >
          {false && e.image && (
            <div className="inline mr-4">
              <img
                className="inline"
                src={e.image.url}
                alt={e.image.alt}
                loading="lazy"
              />
            </div>
          )}
          {/* <div className="w-1 h-4 bg-yellow"></div> */}
          <Link to={e.url} className={"group-hover:text-blue "}>
            {e.label}
          </Link>
          <div className=" hidden group-hover:block border-none px-2">
            <img src={arrow} alt="arrow" className="border inline-block" />
          </div>
        </li>
      ))}
    </ul>
  );
};

export const MobileNav = ({ items }) => {
  const links = items;

  const [subMenuId, setSubMenuId] = useState(0);

  const toggleSubMenu = (id) => {
    if (id === subMenuId) {
      setSubMenuId(0);
    } else {
      setSubMenuId(id);
    }
  };

  return (
    <div className={" "}>
      <ul className="list-none">
        {links.map((item, i) => (
          <li className="pb-6" key={i}>
            {item?.items.length === 0 && (
              <Link to={item.url}>{item.label}</Link>
            )}
            {item?.items.length > 0 && (
              <div className="uppercase  flex" key={i}>
                <button
                  className="uppercase"
                  onClick={() => toggleSubMenu(i + 1)}
                  aria-expanded={subMenuId === i + 1}
          role="button"
                >
                  {item.label}{" "}
                  <img
                    src={arrow2}
                    alt=""
                    className={
                      "inline my-auto ml-2 duration-500 " +
                      (subMenuId === i + 1 ? " rotate-180  " : "")
                    }
                  />
                </button>
              </div>
            )}

            <ul
              className={
                "ml-4 my-1 list-none " +
                (subMenuId === i + 1 ? " block " : " hidden ")
              }
            >
              {item.items.map((e, j) => (
                <li key={j} className="fade py-1">
                  {e.image && (
                    <div className="inline mr-4">
                      <img
                        className="inline"
                        src={e.image.url}
                        alt={e.image.alt}
                      />
                    </div>
                  )}
                  <Link to={e.url}>{e.label}</Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navigation;
