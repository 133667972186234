import React, {
  //useState,
  useEffect,
  //useCallback,
  useRef,
  Fragment,
} from "react";

import { useSelector, useDispatch } from "react-redux";

const SessionTimeout = () => {



  const dispatch = useDispatch();

  let startTimerInterval = useRef();

  const user = useSelector(state => state.user)
  let isAuthenticated = user.isLoggedIn;

  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      dispatch({ type: 'user/logout', payload: {} })
    }, 900000);
  };


  // reset interval timer
  let resetTimer = () => {
    //console.log('resetTimer')
    clearTimeout(startTimerInterval.current);
    if (isAuthenticated) {
      timeChecker();
    }
  }//, [isAuthenticated]);


  // Life cycle hook
  useEffect(() => {
    ['click', 'load', 'scroll'].forEach((event) => {
      window.addEventListener(event, resetTimer);
    });
    timeChecker();
    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, timeChecker]);




  return <Fragment />;
};

export default SessionTimeout;